<template>
  <b-alert
    show
    :variant="type"
    class="custom-badge-component"
    :class="{ purple: color == 'purple' }"
  >
    {{ text }}</b-alert
  >
</template>

<script>
export default {
  props: {
    type: { default: 'warning' },
    color: { required: false },
    text: {
      default:
        'Ce client a déjà des règlements non totalement lettrés, veuillez les utiliser'
    }
  }
};
</script>

<style lang="scss">
.custom-badge-component {
  font-family: 'Montserrat';
  padding-top: 5px;
  padding-bottom: 5px;
  &.purple {
    color: #7b58d5 !important;
    background-color: #f1edfb !important;
    border-color: #7b58d5 !important;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
  }
}
</style>
